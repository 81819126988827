<template>
    <div class="main-wrapper">
        <el-scrollbar :native="false">
            <div class="admin-top">
                <div class="top-left">
                    <span class="left-title">创建实训</span>
                </div>
            </div>
            <div class="train-content">
                <div class="train-item">
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <em>*</em>
                            <span>实训标题</span>
                        </div>
                        <div class="attr-item-value">
                            <el-input v-model="trainForm.train_title" placeholder="请输入实训标题"></el-input>
                        </div>
                    </div>
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <em>*</em>
                            <span>实训分类</span>
                        </div>
                        <div class="attr-item-value">
                            <el-cascader
                                    v-model="trainForm.train_class_id"
                                    :options="trainClassLists"
                                    :props="class_props"
                                    clearable
                                    @change="trainClassesHandler"></el-cascader>
                        </div>
                    </div>
                </div>
                <div class="train-item">
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_introduction.is_edit">
                                <span>{{trainForm.train_introduction.name}}</span>
                                <i class="iconfont" @click="editTitle(1)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_introduction.name"
                                      v-if="trainForm.train_introduction.is_edit"
                                      @keyup.enter.native="saveTitle(1)">
                                <el-button slot="append" @click="saveTitle(1)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="trainForm.train_introduction.content"
                                      maxlength="200"
                                      show-word-limit
                                      placeholder="请输入实训简介">
                            </el-input>
                        </div>
                    </div>
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_target.is_edit">
                                <span>{{trainForm.train_target.name}}</span>
                                <i class="iconfont" @click="editTitle(2)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_target.name"
                                      v-if="trainForm.train_target.is_edit"
                                      @keyup.enter.native="saveTitle(2)">
                                <el-button slot="append" @click="saveTitle(2)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="trainForm.train_target.content"
                                      maxlength="200"
                                      show-word-limit
                                      placeholder="请输入实训目标">
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="train-item">
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_requirement.is_edit">
                                <span>{{trainForm.train_requirement.name}}</span>
                                <i class="iconfont" @click="editTitle(3)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_requirement.name"
                                      v-if="trainForm.train_requirement.is_edit"
                                      @keyup.enter.native="saveTitle(3)">
                                <el-button slot="append" @click="saveTitle(3)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="trainForm.train_requirement.content"
                                      maxlength="200"
                                      show-word-limit
                                      placeholder="请输入实训要求">
                            </el-input>
                        </div>
                    </div>
                    <div class="attr-item right-item">
                        <div class="right-item-sub">
                            <div class="project-duration-attr">
                                <div class="duration-title">
                                    <em>*</em>
                                    <span>实训课时</span>
                                </div>
                                <div class="duration-value-box attr-item-value">
                                    <el-input-number v-model="trainForm.class_hour"
                                                     placeholder="请输入课时"
                                                     :min="1"
                                                     :max="5"
                                                     :controls="false"
                                                     :precision="0">
                                    </el-input-number>
                                    <span>课时</span>
                                </div>
                            </div>
                            <div class="project-duration-attr">
                                <div class="duration-title">
                                    <em>*</em>
                                    <span>实训难度</span>
                                </div>
                                <div class="duration-value-box">
                                    <el-rate v-model="trainForm.difficulty"></el-rate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="train-item">
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_determined_one.is_edit">
                                <span>{{trainForm.train_determined_one.name}}</span>
                                <i class="iconfont" @click="editTitle(4)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_determined_one.name"
                                      v-if="trainForm.train_determined_one.is_edit"
                                      @keyup.enter.native="saveTitle(4)">
                                <el-button slot="append" @click="saveTitle(4)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="trainForm.train_determined_one.content"
                                      maxlength="200"
                                      show-word-limit
                                      placeholder="请输入内容">
                            </el-input>
                        </div>
                    </div>
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_determined_two.is_edit">
                                <span>{{trainForm.train_determined_two.name}}</span>
                                <i class="iconfont" @click="editTitle(5)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_determined_two.name"
                                      v-if="trainForm.train_determined_two.is_edit"
                                      @keyup.enter.native="saveTitle(5)">
                                <el-button slot="append" @click="saveTitle(5)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="trainForm.train_determined_two.content"
                                      maxlength="200"
                                      show-word-limit
                                      placeholder="请输入内容">
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="train-item">
                    <div class="attr-item" style="width: 100%;">
                        <div class="attr-item-title">
                            <div class="title" v-if="!trainForm.train_content.is_edit">
                                <em>*</em>
                                <span>{{trainForm.train_content.name}}</span>
                                <i class="iconfont" @click="editTitle(6)">&#xe622;</i>
                            </div>
                            <el-input v-model="trainForm.train_content.name"
                                      v-if="trainForm.train_content.is_edit"
                                      @keyup.enter.native="saveTitle(6)">
                                <el-button slot="append" @click="saveTitle(6)">确定</el-button>
                            </el-input>
                        </div>
                        <div class="attr-item-value">
                            <tiny-editor id="tiny-item" :init="tiny_init" v-model="trainForm.train_content.content"></tiny-editor>
                        </div>
                    </div>
                </div>
                <div class="pannel-project-attrs-normal">
                    <div class="attr-flex-header space-between-position">
                        <div class="attr-flex-title">
                            <em>*</em>
                            <span>实训评价</span>
                        </div>
                        <div class="attr-flex-action">
                            <el-button type="primary" size="mini" @click="evalueteDialogVisible = true">+ 添加评价</el-button>
                        </div>
                    </div>
                    <div class="attr-flex-content">
                        <el-table :data="trainForm.train_evaluete" border header-cell-class-name="evaluete-header" style="width:100%">
                            <el-table-column prop="number" label="序号" width="100" align="center"></el-table-column>
                            <el-table-column prop="content" label="内容"></el-table-column>
                            <el-table-column prop="requirement" label="要求"></el-table-column>
                            <el-table-column prop="score" label="分值" width="100" align="center"></el-table-column>
                            <el-table-column label="操作" width="150" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" @click="editEvaluete(scope.$index, scope.row)" class="text-primary">编辑</el-button>
                                    <el-button type="text" size="small" @click="deleteEvaluete(scope.$index, scope.row)" class="text-danger">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pannel-project-attrs-normal">
                    <div class="attr-flex-header">
                        <div class="attr-flex-title">
                            上传素材
                        </div>
                    </div>
                    <div class="attr-flex-content">
                        <el-upload
                                class="upload-sucai"
                                action="/train/upload"
                                :on-remove="handleRemove"
                                :before-remove="beforeRemove"
                                :headers="headerObj"
                                :on-success="handleSuccess"
                                name="file"
                                multiple
                                :file-list="trainForm.source_material">
                            <div class="sucai-upload-area">
                                <el-button size="small" type="primary" class="sucai-upload"><i class="iconfont">&#xe662;</i>&nbsp;&nbsp;点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">(文件支持格式为mp4,avi,pdf,word,excel,ppt,jpg,png,zip和rar)</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="train-item">
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <em>*</em>
                            <span>实训类型</span>
                        </div>
                        <div class="attr-item-value">
                            <el-select v-model="trainType" placeholder="请选择" class="platform-select" @change="chooseTrainType">
                                <el-option
                                        v-for="item in trainTypeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="attr-item">
                        <div class="attr-item-title">
                            <span>关联脚本</span>
                        </div>
                        <div class="attr-item-value">
                            <el-select v-model="trainScript" placeholder="请选择" class="platform-select" @change="chooseTrainScript" clearable>
                                <el-option
                                        v-for="item in trainScriptList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="pannel-project-attrs-normal">
                    <div class="form-submit-buttons">
                        <el-button type="primary" @click="saveTrain">保存</el-button>
                        <el-button @click="goToTrainList">返回</el-button>
                    </div>
                </div>
            </div>
            <!-- 添加实训评价弹窗-->
            <div class="evaluate-dialog">
                <el-dialog
                        title="新增实训评价"
                        :visible.sync="evalueteDialogVisible"
                        @close="resetForm()"
                        width="500px">
                    <el-form ref="evaluate_form" :model="evaluate_form" :rules="evaluateRules" label-width="80px">
                        <el-form-item label="序号" prop="number" required>
                            <el-input v-model.number="evaluate_form.number" style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="内容" prop="content" required>
                            <el-input v-model="evaluate_form.content" style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="要求" prop="requirement" required>
                            <el-input v-model="evaluate_form.requirement" type="textarea" rows="4" style="width: 300px"></el-input>
                        </el-form-item>
                        <el-form-item label="分值" prop="score" required>
                            <el-input v-model.number="evaluate_form.score" style="width: 300px"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="evalueteDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitEform('evaluate_form')">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue"
    export default {
        name: "CreateTrain",
        components: {
            'tiny-editor': Editor
        },
        data() {
            return {
                //富文本框设置
                tiny_init: {
                    selector: '#tinydemo',
                    paste_data_images: true, // 默认是false的，记得要改为true才能粘贴
                    menubar: false,
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen| paste'
                    ],
                    toolbar_drawer: "sliding",
                    // quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image,paste",
                    language: 'zh_CN', //本地化设置
                    height: 568,
                    //上传图片
                    images_upload_handler: function (blobInfo, success,failure,progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'train/rich/upload');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization",localStorage.getItem('token'));

                        xhr.upload.onprogress = function(e){
                            progress(e.loaded / e.total * 100);
                        }

                        xhr.onload = function() {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, { remove: true });
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300 ) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
                //创建实训表单数据
                trainForm: {
                    train_title: '',
                    train_class_id: '',
                    train_introduction: {
                        name: '实训简介',
                        content: '',
                        is_edit: false
                    },
                    train_target: {
                        name: '实训目标',
                        content: '',
                        is_edit: false
                    },
                    train_requirement: {
                        name: '实训要求',
                        content: '',
                        is_edit: false
                    },
                    class_hour: null,
                    difficulty: null,
                    train_determined_one: {
                        name: '待定栏目一',
                        content: '',
                        is_edit: false
                    },
                    train_determined_two: {
                        name: '待定栏目二',
                        content: '',
                        is_edit: false
                    },
                    train_content: {
                        name: '实训任务',
                        content: '',
                        is_edit: false
                    },
                    train_evaluete: [],
                    source_material: [],
                },
                //实训分类
                trainClassLists: [],
                class_props: {
                    label: 'class_name',
                    value: 'id'
                },
                //是否显示添加实训评价弹窗
                evalueteDialogVisible: false,
                //添加实训评价表单数据
                evaluate_form: {
                    number: null,
                    content: '',
                    requirement: '',
                    score: null
                },
                evaluate_form_index: null,
                evaluateRules: {
                    number: [
                        { required: true, message: '请输入评价序号', trigger: 'blur' },
                        { required: true, message: '请输入评价序号', trigger: 'change' },
                        { type: 'number', message: '序号必须为正整数', trigger: 'blur'},
                        { type: 'number', message: '序号必须为正整数', trigger: 'change'}
                    ],
                    content: [
                        { required: true, message: '请输入评价内容', trigger: 'blur' },
                        { required: true, message: '请输入评价内容', trigger: 'change' }
                    ],
                    requirement: [
                        { required: true, message: '请输入评价要求', trigger: 'blur' },
                        { required: true, message: '请输入评价要求', trigger: 'change' }
                    ],
                    score: [
                        { required: true, message: '请输入评价分值', trigger: 'blur' },
                        { required: true, message: '请输入评价分值', trigger: 'change' },
                        { type: 'number', message: '评分分值必须为正整数', trigger: 'blur' },
                        { type: 'number', message: '评分分值必须为正整数', trigger: 'change'}
                    ]
                },
                //上传素材的头部token
                headerObj: {
                    'Authorization': localStorage.getItem('token'),
                },
                //实训类型
                trainTypeList: [
                    {
                        label: '实训',
                        value: 1
                    },
                    {
                        label: '图文实训',
                        value: 2
                    },
                    {
                        label: '短视频实训',
                        value: 3
                    }
                ],
                trainType: 1,
                //脚本列表
                trainScriptList: [],
                trainScript: null
            }
        },
        mounted() {
          this.getTrainClassLists();
          this.getScriptList();
          if (this.$route.query.id) {
              this.getTrainDetail();
          }
        },
        methods: {
            //获取实训详情
            getTrainDetail() {
                let param = {
                    id: this.$route.query.id
                }
                this.$http.axiosGetBy(this.$api.train, param, (res) => {
                    if (res.code === 200) {
                        this.trainForm.train_title = res.data.title;
                        this.trainForm.train_class_id = res.data.train_class_id;
                        if (res.data.introduction) {
                            this.trainForm.train_introduction.name = res.data.introduction.name;
                            this.trainForm.train_introduction.content = res.data.introduction.content;
                        }
                        if (res.data.target) {
                            this.trainForm.train_target.name = res.data.target.name;
                            this.trainForm.train_target.content = res.data.target.content;
                        }
                        if (res.data.requirement) {
                            this.trainForm.train_requirement.name = res.data.requirement.name;
                            this.trainForm.train_requirement.content = res.data.requirement.content;
                        }

                        this.trainForm.class_hour = res.data.class_hour;
                        this.trainForm.difficulty = res.data.difficulty;
                        if (res.data.determined_column) {
                            if (res.data.determined_column.length > 0) {
                                this.trainForm.train_determined_one.name = res.data.determined_column[0].name;
                                this.trainForm.train_determined_one.content = res.data.determined_column[0].content;
                                if (res.data.determined_column.length === 2) {
                                    this.trainForm.train_determined_two.name = res.data.determined_column[1].name;
                                    this.trainForm.train_determined_two.content = res.data.determined_column[1].content;
                                }
                            }
                        }
                        this.trainForm.train_content.name = res.data.content.name;
                        this.trainForm.train_content.content = res.data.content.content;
                        this.trainForm.train_evaluete = res.data.evaluate;
                        this.trainType = res.data.type;
                        if (res.data.script_id > 0) {
                            this.trainScript = res.data.script_id;
                        }
                        if (res.data.source_material) {
                            if (res.data.source_material.length > 0) {
                                this.trainForm.source_material = res.data.source_material;
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取分类
            getTrainClassLists(){
                this.$http.axiosGet(this.$api.trainClass_list ,(res)=>{
                    console.log('实训分类',res)
                    if(res.code === 200){
                        this.trainClassLists = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择实训分类
            trainClassesHandler(val) {
                console.log('val11',val)
                this.trainForm.train_class_id = val;
            },
            //修改实训简介的标题
            editTitle(type) {
                if (type === 1) {
                    this.trainForm.train_introduction.is_edit = !this.trainForm.train_introduction.is_edit;
                } else if (type === 2) {
                    this.trainForm.train_target.is_edit = !this.trainForm.train_target.is_edit;
                } else if (type === 3) {
                    this.trainForm.train_requirement.is_edit = !this.trainForm.train_requirement.is_edit;
                } else if (type === 4) {
                    this.trainForm.train_determined_one.is_edit = !this.trainForm.train_determined_one.is_edit;
                } else if (type === 5) {
                    this.trainForm.train_determined_two.is_edit = !this.trainForm.train_determined_two.is_edit;
                } else {
                    this.trainForm.train_content.is_edit = !this.trainForm.train_content.is_edit;
                }
            },
            //保存
            saveTitle(type) {
                if (type === 1) {
                    this.trainForm.train_introduction.is_edit = !this.trainForm.train_introduction.is_edit;
                } else if (type === 2) {
                    this.trainForm.train_target.is_edit = !this.trainForm.train_target.is_edit;
                } else if (type === 3) {
                    this.trainForm.train_requirement.is_edit = !this.trainForm.train_requirement.is_edit;
                } else if (type === 4) {
                    this.trainForm.train_determined_one.is_edit = !this.trainForm.train_determined_one.is_edit;
                } else if (type === 5) {
                    this.trainForm.train_determined_two.is_edit = !this.trainForm.train_determined_two.is_edit;
                } else {
                    this.trainForm.train_content.is_edit = !this.trainForm.train_content.is_edit;
                }
            },
            //编辑实训评价
            editEvaluete(index, row) {
                this.evaluate_form = {...row };
                this.evaluate_form_index = index;
                this.evalueteDialogVisible = true;
            },
            //删除实训评价
            deleteEvaluete(index, row) {
                this.$confirm('此操作将永久删除该评价，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.trainForm.train_evaluete.splice(index, 1);
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //提交实训评价
            submitEform(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let evaluate_obj = Object.assign({}, this.evaluate_form);
                        if (this.evaluate_form_index !== null) {
                            this.$set(this.trainForm.train_evaluete, this.evaluate_form_index, evaluate_obj);
                            this.evaluate_form_index = null;
                        } else {
                            this.trainForm.train_evaluete.push(evaluate_obj);
                        }
                        this.$refs[formName].resetFields();
                        this.evalueteDialogVisible = false;
                    } else {
                        return false;
                    }
                })
            },
            //上传素材相关操作
            handleRemove(file, fileList) {
                this.trainForm.source_material.forEach((item) => {
                    return item.uid !== file.uid;
                })
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            handleSuccess(res, file) {
                let obj = {}
                obj.name = file.name;
                obj.url = file.response.data.url;
                this.trainForm.source_material.push(obj);
            },
            //选择的实训类型
            chooseTrainType(val) {
                this.trainType = val;
            },
            //选择的脚本
            chooseTrainScript(val) {
                this.trainScript = val;
            },
            //获取脚本列表
            getScriptList() {
                this.$http.axiosGetBy(this.$api.script, {status: 1}, (res) => {
                    if (res.code === 200) {
                        this.trainScriptList = res.data.data;
                        // if (this.trainScript === null) {
                        //     this.trainScript = res.data.data[0].id;
                        // }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //保存实训
            saveTrain() {
                try {
                    if (this.trainForm.train_title.length === 0) {
                        throw new Error('请输入实训标题');
                    }
                    if (this.trainForm.train_title.length < 2 || this.trainForm.train_title.length > 40) {
                        throw new Error('实训标题长度在2-40个字符');
                    }
                    if (this.trainForm.train_class_id.length === 0) {
                        throw new Error('实训分类必选');
                    }
                    if (!this.trainForm.class_hour) {
                        throw new Error('实训课时必填');
                    }
                    if (!this.trainForm.difficulty) {
                        throw new Error('实训难度必填');
                    }
                    if (this.trainForm.train_content.content === '') {
                        throw new Error('实训任务不能为空');
                    }
                    if (/<p>(&nbsp; |&nbsp;)+<\/p>/.test(this.trainForm.train_content.content)) {
                        throw new Error('实训任务不能为空');
                    }
                    if (this.trainForm.train_evaluete.length === 0) {
                        throw new Error('实训评价必填');
                    }
                } catch (error) {
                    this.$message.warning(error.message);
                    return;
                }
                let formData = new FormData();
                if (this.$route.query.id) {
                    formData.append('id',this.$route.query.id);
                    if (this.trainForm.train_class_id.length >= 2) {
                        formData.append('train_class_id', this.trainForm.train_class_id[this.trainForm.train_class_id.length - 1]);
                    } else {
                        formData.append('train_class_id', this.trainForm.train_class_id);
                    }
                } else {
                    formData.append('train_class_id', this.trainForm.train_class_id[this.trainForm.train_class_id.length - 1]);
                }
                let determined_column = [];
                if (this.trainForm.train_determined_one.content) {
                    determined_column.push(this.trainForm.train_determined_one);
                }
                if (this.trainForm.train_determined_two.content) {
                    determined_column.push(this.trainForm.train_determined_two);
                }
                formData.append('title',this.trainForm.train_title);
                if (this.trainForm.train_introduction.content) {
                    formData.append('introduction',JSON.stringify(this.trainForm.train_introduction));
                }
                if (this.trainForm.train_target.content) {
                    formData.append('target',JSON.stringify(this.trainForm.train_target));
                }
                if (this.trainForm.train_requirement.content) {
                    formData.append('requirement',JSON.stringify(this.trainForm.train_requirement));
                }
                formData.append('class_hour',this.trainForm.class_hour);
                formData.append('difficulty',this.trainForm.difficulty);
                if (this.trainForm.train_determined_one.content || this.trainForm.train_determined_two.content) {
                    formData.append('determined_column',JSON.stringify(determined_column));
                }
                formData.append('content',JSON.stringify(this.trainForm.train_content));
                formData.append('evaluate',JSON.stringify(this.trainForm.train_evaluete));
                if (this.trainForm.source_material.length > 0) {
                    formData.append('source_material',JSON.stringify(this.trainForm.source_material));
                }
                formData.append('type',this.trainType);
                if (this.trainScript) {
                    formData.append('script_id',this.trainScript);
                }
                this.$http.axiosPost(this.$api.train, formData, (res) => {
                    if (res.code === 200) {
                        this.$router.push({
                            path: '/admin/train/list'
                        })
                        this.$message.success(res.message);
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //重置评价表单数据
            resetForm() {
                this.evaluate_form =  {
                    number: null,
                    content: '',
                    requirement: '',
                    score: null
                }
            },
            //返回
            goToTrainList() {
                this.$router.push({
                    path: '/admin/train/list'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .train-content {
        flex: 1;
        height: 1%;
        border-top: 1px solid #F6F5F8;
        padding: 25px 40px;
        .train-item {
            display: flex;
            justify-content: space-between;
        }
        .attr-item {
            .right-item-sub {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .project-duration-attr {
                    .duration-title {
                        margin-bottom: 14px;
                        em {
                            color: #FF0000;
                        }
                        span {
                            color: #333333;
                            margin-left: 8px;
                        }
                    }
                    .duration-value-box {
                        ::v-deep .el-input {
                            width: 150px;
                        }
                        /*span {*/
                            /*margin-left: 8px;*/
                        /*}*/
                    }
                }
            }
            width: 360px;
            font-size: 14px;
            margin-bottom: 40px;
            .attr-item-title {
                margin-bottom: 14px;
                em {
                    color: #FF0000;
                }
                span {
                    color: #333333;
                    margin-left: 8px;
                }
                .title {
                    i {
                        color: #409EFF;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
            .attr-item-value {
                ::v-deep .el-cascader {
                    width: 100%;
                }
                ::v-deep .el-input__inner,
                ::v-deep .el-textarea__inner {
                    background-color: #F5F7FA;
                    resize: none;
                    &::placeholder {
                        color: #999999;
                    }
                }
                .platform-select {
                    width: 100%;
                }
            }
            .duration-value-box {
                display: flex;
                align-items: center;
                height: 40px;
            }
        }
    }
    .pannel-project-attrs-normal {
        width: 100%;
        margin-bottom: 40px;
        .attr-flex-header {
            height: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            &.space-between-position {
                justify-content: space-between;
            }
        }
        .attr-flex-content {
            ::v-deep .evaluete-header {
                background-color: #F5F7FA;
            }
            ::v-deep .el-textarea__inner {
                background-color: #F5F7FA;
            }
            .upload-sucai {
                .sucai-upload-area {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .el-upload__tip {
                        margin-left: 20px;
                        font-size: 14px;
                        color: #999999;
                    }
                }
                ::v-deep .el-button {
                    background-color: #DCECFE;
                    border: 1px solid #DCECFE;
                    span {
                        color: #409EFF;
                        i.iconfont {
                            font-size: 14px;
                        }
                    }
                }
                ::v-deep .el-upload-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                }
                ::v-deep .el-upload-list__item {
                    width: 228px;
                    margin-right: 21px;
                }
                ::v-deep .el-upload-list__item-name {
                    .el-icon-document {
                        color: #409eff;
                        font-size: 18px;
                    }
                }
            }
        }
        .form-submit-buttons {
            margin-top: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            ::v-deep .el-button--primary {
                color: #FFF;
                background-color: #1D35E3;
                border-color: #1D35E3;
            }
        }
        .attr-flex-action {
            ::v-deep .el-button--primary {
                color: #FFF;
                background-color: #1D35E3;
                border-color: #1D35E3;
            }
        }
        .attr-flex-title {
            em {
                color: #FF0000;
            }
            span {
                margin-left: 8px;
            }
        }
    }

</style>